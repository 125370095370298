import { useUserState } from 'hooks/state/useUserState';
import useSWR from 'swr';
import { getMypage } from 'lib/api/users/mypage';

export function useMypage() {
	const [user] = useUserState();
	const { data, error, isLoading } = useSWR(
		`/mypage`,
		async () => await getMypage(user.token),
	);

	return { data, isLoading };
}
