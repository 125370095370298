import { AxiosResponse } from 'axios';
import { instance } from 'components/organisms/AxiosClientProvider';
import { MyPageResponseType } from 'types/api/users/mypage';

export async function getMypage(token: string) {
	try {
		const response: AxiosResponse<MyPageResponseType> = await instance.get(
			`${process.env.API_ENDPOINT}/api/v1/users/mypage`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		);

		return response.data;
	} catch (e) {}
}
