import Calendar from 'react-calendar';
import { MyPageCalendarType } from './types';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'utils/date';
import { calendarStyle, customTileLabel } from './styles';

export function MyPageCalendar({
	value,
	className,
	schedules,
}: MyPageCalendarType) {
	function CustomTile({ date }: { date: Date }) {
		const year = date.getFullYear().toString();
		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const formattedDate = `${year}${month}${day}`;
		const schedule = schedules.find((d) => d.date === formattedDate);

		if (date.getMonth() !== value.getMonth()) return <></>;
		return (
			<>
				<div>{date.getDate()}</div>
				<div css={customTileLabel(schedule?.color_code)}>{schedule?.label}</div>
			</>
		);
	}

	return (
		<Calendar
			css={calendarStyle}
			className={className}
			locale='ja-JP'
			calendarType='US'
			value={value}
			minDate={getFirstDayOfMonth(value.getFullYear(), value.getMonth())}
			maxDate={getLastDayOfMonth(value.getFullYear(), value.getMonth())}
			onChange={() => {}}
			showNavigation={false}
			tileContent={CustomTile}
		/>
	);
}
