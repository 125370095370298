import { useUserState } from 'hooks/state/useUserState';
import { getWebIndex } from 'lib/api/users/web';
import { useState } from 'react';
import useSWR from 'swr';
import { WebIndexParamsType } from 'types/api/users/webIndex';

export function useWebIndex() {
	const [user] = useUserState();
	const [params, setParams] = useState<WebIndexParamsType | undefined>();
	const { data, error, isLoading } = useSWR(
		`/web+${Object.values(params || {})}`,
		async () => (params ? await getWebIndex(user.token, params) : undefined),
	);

	return { data, isLoading, setParams };
}
