import { useWebIndexBody } from 'hooks/body/useWebIndexBody';
import { NoticePresenter } from './presenter';
import { useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { ANIMATION_DURATION } from './styles';

export function Notice() {
	const { data, isLoading } = useWebIndexBody();
	const [isFirstLoop, setIsFirstLoop] = useState(true);
	const [windowWidth] = useWindowSize();

	if (isLoading || !data || !data.notice || data.notice.length === 0)
		return <></>;

	// NOTE: かなり無理矢理な方法だが、最初のループと2回目以降のループでアニメーションの開始位置を変えるために使っている
	setTimeout(() => {
		setIsFirstLoop(false);
	}, data.notice.join(' ').length * ANIMATION_DURATION);

	return (
		<NoticePresenter
			notice={data.notice}
			isFirstLoop={isFirstLoop}
			windowWidth={windowWidth}
		/>
	);
}
