import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const marketTemplateWrapper = (theme: Theme) => css`
	background-color: ${theme.users.colors.background.primary};
	${maxScreen('mobile')} {
		background-color: white;
	}
`;

export const marketTemplateInnerWrapper = (
	theme: Theme,
	bgImagePc?: string,
	bgImageMo?: string,
) => css`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 0 ${theme.users.spacing.desktop.pageInnerHorizontal};
	${bgImagePc
		? `background-image: url(${bgImagePc});`
		: `background-color: ${theme.users.colors.background.primary};`}
	background-size: cover;
	${maxScreen('mobile')} {
		padding: 0;
		${bgImageMo
			? `background-image: url(${bgImageMo});`
			: `background-color: ${theme.users.colors.background.primary};`}
	}
`;

export const marketTemplateInner = (theme: Theme) => css`
	width: 100%;
	max-width: ${theme.users.width.desktop.marketInner};
	min-height: calc(100vh - 222px);
	padding-bottom: 80px;
	${maxScreen('mobile')} {
		padding: 0 7vw 7vw;
		min-width: auto;
		max-width: auto;
		min-height: calc(100vh - 165.33px);
	}
`;
