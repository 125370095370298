import { MyPageBody } from 'components/organisms/Body/MyPageBody';
import { PageH1 } from 'components/organisms/Heading/PageH1';
import { FixedWidthTemplate } from 'components/templates/FixedWidthTemplate';
import { NextSeo } from 'next-seo';

export default function Home() {
	return (
		<FixedWidthTemplate isNavVisible isNoticeVisible>
			<NextSeo title='マイページ' description='' />
			<PageH1 label='マイページ' />
			<MyPageBody />
		</FixedWidthTemplate>
	);
}
