import { useWebIndex } from 'hooks/api/useWebIndex';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { WebIndexParamsType } from 'types/api/users/webIndex';
import { flowerConvenienceFilter, flowerFilter } from 'utils/dictionary';

export function generateQueryObject(query: {
	[k: string]: any;
}): WebIndexParamsType {
	const filter = Object.keys(flowerFilter).includes(query.filter)
		? (query.filter as keyof typeof flowerFilter)
		: '';
	const convenience_filter = Object.keys(flowerConvenienceFilter).includes(
		query.convenience_filter || '',
	)
		? (query.convenience_filter as keyof typeof flowerConvenienceFilter)
		: 'default';

	return {
		variety_name: query.variety_name || '',
		item_name: query.item_name || '',
		color: query.color || '',
		category: query.category || '',
		prefecture: query.prefecture || '',
		shipper: query.shipper || '',
		listing_date: query.listing_date || '',
		sale_date: query.sale_date || '',
		filter: filter,
		convenience_filter: convenience_filter,
	};
}

export function useWebIndexBody() {
	const { data, isLoading, setParams } = useWebIndex();
	const router = useRouter();

	useEffect(() => {
		if (!router) return;
		const query = router.query as { [k: string]: string };

		setParams(generateQueryObject(query));
		/* setParamsを依存関係に持たせないため */
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [router]);

	return { data, isLoading };
}
