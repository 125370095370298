import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';
export const myPageH2Wrapper = (theme: Theme) => css`
	display: flex;
	align-items: center;
	margin: 0 0 calc(25px - 0.25rem);
	h2 {
		margin-bottom: 0.25rem;
	}
	p {
		font-size: 0.8rem;
	}
	${maxScreen('mobile')} {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 25px;
	}
`;

export const myPageBodyH2 = (theme: Theme) => css`
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.5;
	margin-right: 2rem;
`;

export const myPageUlWrapper = (theme: Theme) => css`
	border-bottom: 1px solid ${theme.users.colors.border.default};
	margin-bottom: 3rem;
	padding-bottom: 0.5rem;
`;

export const myPageListWrapper = (theme: Theme) => css`
	display: flex;
	margin-bottom: 1.5rem;
	margin-left: 1.5rem;
	${maxScreen('mobile')} {
		flex-direction: column;
		align-items: flex-start;
		font-size: 4vw;
		line-height: 1.9;
		margin-bottom: 1rem;
		margin-left: 0;
	}
`;

// ここを固定値にする（10文字以内）
export const myPageListLabel = (theme: Theme) => css`
	width: 10rem;
	color: ${theme.users.colors.link.newsVisited};
	border: 1px solid ${theme.users.colors.link.newsVisited};
	border-radius: 4px;
	margin-right: 1rem;
	font-size: 0.8rem;
	line-height: calc(1.5rem - 2px);
	text-align: center;
`;

export const myPageListTime = (theme: Theme) => css`
	color: ${theme.users.colors.link.newsVisited};
	width: 120px;
	${maxScreen('mobile')} {
		width: auto;
		margin-right: 1rem;
	}
`;

export const myPageListLink = (theme: Theme) => css`
	color: ${theme.users.colors.link.newsIndex};
	&:visited {
		color: ${theme.users.colors.link.newsVisited};
	}
`;

export const myPageLink = (theme: Theme) => css`
	color: ${theme.users.colors.link.newsIndex};
	margin-right: 1rem;
	&:visited {
		color: ${theme.users.colors.link.newsVisited};
	}
`;

export const myPageCalendarWarapper = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
	${maxScreen('mobile')} {
		flex-direction: column;
	}
`;

export const myPageCalendar = (theme: Theme) => css`
	width: calc(50% - 25px);
	${maxScreen('mobile')} {
		width: 100%;
		margin-bottom: 6vw;
	}
`;

export const myPageCalendarCaption = (theme: Theme) => css`
	display: flex;
	justify-content: start;
	margin: 0 0 20px;
	font-weight: bold;
	&::before {
		content: '■';
		color: ${theme.users.colors.primary};
		margin: 0px 6px 0px 0px;
	}
`;

export const myPageLinkWrapper = (theme: Theme) => css`
	display: flex;
	justify-content: center;
	margin-bottom: 60px;
`;
