import { Theme, css, keyframes } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const ANIMATION_DURATION = 600;

export const noticeWrapper = (them: Theme) => css`
	display: flex;
	background-color: #f0f0f0;
	overflow: hidden;
	padding: 15px 0;
	white-space: nowrap;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	${maxScreen('mobile')} {
		padding: 0.6rem 0;
	}
`;

export const noticeList = (
	noticeTextLength: number,
	isFirstLoop: boolean,
	windowWidth: number,
) => css`
	animation: ${noticeAnimation(isFirstLoop, windowWidth)}
		${noticeTextLength * ANIMATION_DURATION}ms infinite linear;

	${maxScreen('tablet')} {
		animation: ${tabletNoticeAnimation(isFirstLoop, windowWidth)}
			${noticeTextLength * ANIMATION_DURATION}ms infinite linear;
	}

	${maxScreen('mobile')} {
		animation: ${mobileNoticeAnimation(isFirstLoop, windowWidth)}
			${noticeTextLength * ANIMATION_DURATION}ms infinite linear;
	}
`;

// NOTE: かなり無理矢理な方法だが、最初のループと2回目以降のループでアニメーションの開始位置を変えるために使っている
const noticeAnimation = (
	isFirstLoop: boolean,
	windowWidth: number,
) => keyframes`
  0% {transform: translateX(${isFirstLoop ? '30rem' : `${windowWidth}px`});}
  100% {transform: translateX(-100%);}
`;

const tabletNoticeAnimation = (
	isFirstLoop: boolean,
	windowWidth: number,
) => keyframes`
  0% {transform: translateX(${isFirstLoop ? '18rem' : `${windowWidth}px`});}
  100% {transform: translateX(-100%);}
`;

const mobileNoticeAnimation = (
	isFirstLoop: boolean,
	windowWidth: number,
) => keyframes`
  0% {transform: translateX(${isFirstLoop ? '8rem' : `${windowWidth}px`});}
  100% {transform: translateX(-100%);}
`;

export const noticeItem = (theme: Theme) => css`
	display: inline-block;
	font-size: 1.2rem;
	color: ${theme.users.colors.primary};
	margin-right: 12rem;
	font-weight: bold;
	${maxScreen('mobile')} {
		font-size: 0.9rem;
		margin-right: 9rem;
	}
`;
