export const flowerCategory = {
	1: '切花',
	2: '園芸',
};

export const flowerFilter = {
	'': 'すべて',
	exclude_sold_out: '完売商品を除く',
	purchased: '購入済み',
};

export const flowerConvenienceFilter = {
	default: '---',
	recommend: 'おすすめ品表示',
};
