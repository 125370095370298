import { Header } from 'components/organisms/Header';
import {
	marketTemplateInner,
	marketTemplateInnerWrapper,
	marketTemplateWrapper,
} from './styles';
import { MarketTemplateType } from './types';
import { HeaderGlobalNavigation } from 'components/organisms/Navigation/HeaderGlobal';
import { DefaultFooter } from 'components/organisms/Footer/DefaultFooter';
import { useTheme } from '@emotion/react';
import { Notice } from 'components/organisms/Notice';

export function FixedWidthTemplate({
	children,
	isNavVisible,
	isNoticeVisible,
	bgImagePc,
	bgImageMo,
}: MarketTemplateType) {
	const theme = useTheme();

	return (
		<div
			css={marketTemplateWrapper}
			className='relative min-h-full w-full pb-[70px]'
		>
			<Header />
			{isNavVisible && <HeaderGlobalNavigation />}
			{isNoticeVisible && <Notice />}
			<div css={marketTemplateInnerWrapper(theme, bgImagePc, bgImageMo)}>
				<main css={marketTemplateInner}>{children}</main>
			</div>
			<DefaultFooter />
		</div>
	);
}
