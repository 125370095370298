import { noticeWrapper, noticeList, noticeItem } from './styles';
import { NoticePresenterType } from './types';

export function NoticePresenter({
	notice,
	isFirstLoop,
	windowWidth,
}: NoticePresenterType) {
	return (
		<div css={noticeWrapper}>
			<ul css={noticeList(notice.join(' ').length, isFirstLoop, windowWidth)}>
				{notice.map((item, index) => (
					<li key={index} css={noticeItem}>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
}
