import { Theme, css } from '@emotion/react';
import { maxScreen } from 'styles/mediaQuery';

export const calendarStyle = (theme: Theme) => css`
	.react-calendar__month-view__days {
		border: 0.5px solid ${theme.users.colors.border.default};
	}

	.react-calendar__month-view__weekdays {
		border: 0.5px solid ${theme.users.colors.border.default};
		border-bottom: none;
		text-align: center;
		font-weight: bold;
	}

	.react-calendar__month-view__weekdays__weekday {
		background-color: #f1f1f1;
		border: 0.5px solid ${theme.users.colors.border.default};
		border-bottom: none;
		padding: 8px;
	}

	.react-calendar__month-view__days__day {
		font-size: 0px;
	}

	button {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		min-height: 60px;
		border: 0.5px solid ${theme.users.colors.border.default};
		background-color: white;
		padding: 8px;
		${maxScreen('mobile')} {
			padding: 4px;
		}
		div {
			font-size: 0.75rem;
		}
	}

	button:disabled {
		font-size: 0;
		background-color: #f1f1f1;
		div {
			font-size: 0;
		}
	}
`;

export const customTileLabel = (colorCode?: string) => css`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.6875rem !important;
	background-color: ${colorCode || 'white'};
	border-radius: 2px;
	padding: 2px 0;
	margin-top: 0.25rem;
	width: 100%;
`;
