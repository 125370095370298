import { SecondaryLink } from 'components/molecules/Links/Secondary';
import {
	myPageBodyH2,
	myPageCalendar,
	myPageCalendarCaption,
	myPageCalendarWarapper,
	myPageH2Wrapper,
	myPageLink,
	myPageLinkWrapper,
	myPageListLabel,
	myPageListLink,
	myPageListTime,
	myPageListWrapper,
	myPageUlWrapper,
} from './styles';
import Link from 'next/link';
import { MyPageBodyPresenterType } from './types';
import { MyPageCalendar } from 'components/organisms/MyPageCalendar';
import { getNextMonthDate } from 'utils/date';
import { Oval } from 'react-loader-spinner';
import { useTheme } from '@emotion/react';

export function MyPageBodyPresenter({
	data,
	isLoading,
}: MyPageBodyPresenterType) {
	const theme = useTheme();

	return (
		<div>
			<div>
				<div css={myPageH2Wrapper}>
					<h2 css={myPageBodyH2}>花市場からのお知らせ</h2>
				</div>
				<Oval
					wrapperClass='flex justify-center !py-[2rem]'
					height={96}
					color={theme.users.colors.primary}
					visible={isLoading}
				/>
				{data?.news && (
					<ul css={myPageUlWrapper}>
						{data.news.map((news) => (
							<li css={myPageListWrapper} key={`news-${news.INFORMATION_CD}`}>
								<div className='flex items-center'>
									<div css={myPageListLabel}>{news.CATEGORY_NM}</div>
									<time css={myPageListTime}>{news.DATE}</time>
								</div>
								<Link
									href={`/news/${news.INFORMATION_CD}`}
									css={myPageListLink}
								>
									{news.TITLE}
								</Link>
							</li>
						))}
					</ul>
				)}
				<div css={myPageLinkWrapper}>
					<SecondaryLink href='/news'>一覧を見る</SecondaryLink>
				</div>
			</div>
			<div css={myPageH2Wrapper}>
				<h2 css={myPageBodyH2}>市場カレンダー</h2>
				<p>{data?.calendar_note}</p>
			</div>
			<Oval
				wrapperClass='flex justify-center !py-[2rem]'
				height={96}
				color={theme.users.colors.primary}
				visible={isLoading}
			/>
			{data?.schedules && (
				<div css={myPageCalendarWarapper}>
					<div css={myPageCalendar}>
						<caption css={myPageCalendarCaption}>
							{new Date().getFullYear()}年{new Date().getMonth() + 1}月
						</caption>
						<MyPageCalendar value={new Date()} schedules={data.schedules} />
					</div>
					<div css={myPageCalendar}>
						<caption css={myPageCalendarCaption}>
							{getNextMonthDate().getFullYear()}年
							{getNextMonthDate().getMonth() + 1}月
						</caption>
						<MyPageCalendar
							value={getNextMonthDate()}
							schedules={data.schedules}
						/>
					</div>
				</div>
			)}
			<div css={myPageH2Wrapper}>
				<h2 css={myPageBodyH2}>リンク集</h2>
			</div>
			<Oval
				wrapperClass='flex justify-center !py-[2rem]'
				height={96}
				color={theme.users.colors.primary}
				visible={isLoading}
			/>
			{data?.links && (
				<>
					{data.links.map((link) => (
						<Link href={link.link} key={`link-${link.label}`} css={myPageLink}>
							{link.label}
						</Link>
					))}
				</>
			)}
		</div>
	);
}
